/**
 * Loan Api
 * 1.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from 'oazapfts/lib/runtime';
import * as QS from 'oazapfts/lib/runtime/query';
export const defaults: Oazapfts.RequestOpts = {
  baseUrl: '/',
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type LoanSearchResponse = {
  contractId?: string | null;
  companyId?: string | null;
  companyLasId?: string | null;
  principalAmount?: number | null;
  startDate?: string;
  loanPurpose?: string | null;
  notionalAmount?: number;
  maturityDate?: string;
  loantype?: ContractLoanTypeEnum;
  greenLoan?: boolean;
  loanStatus?: ContractStatus;
};
export type ValidationErrorResponse = {
  validationErrors?: string[] | null;
};
export type ErrorResponse = {
  errorMessage?: string[] | null;
};
export type LoanSearchRelationResponse = {
  contractId?: string | null;
  companyLasId?: string | null;
  relationCompanyLasId?: string | null;
  relationCompanyId?: string | null;
  relationType?: string | null;
  liabilityPercentage?: number | null;
  liabilityAmount?: number | null;
};
export type AmortListResponse = {
  valueDate?: string;
  repayment?: number | null;
  interest?: number | null;
  margin?: number | null;
  additionalPayment?: number | null;
  payment?: number | null;
  notionalAmount?: number | null;
  notifiedDate?: string | null;
  knownInterest?: boolean;
  interestRateStatus?: InterestRateStatus;
};
export type TimeMeasure = {
  years?: number;
  months?: number;
  days?: number;
};
export type LoanInfoResponse = {
  contractId?: string | null;
  companyId?: string | null;
  companyLasId?: string | null;
  principalAmount?: number;
  startDate?: string;
  loanPurpose?: string | null;
  notionalAmount?: number;
  maturityDate?: string;
  loanProduct?: ContractLoanTypeEnum;
  sustainableFramework?: SustainableFrameworkType;
  greenLoan?: boolean;
  currency?: CurrencyCode;
  redemptionValue?: number;
  interestRate?: number;
  nextFixingDate?: string | null;
  interestPaymentFrequency?: PaymentFreqEnum;
  spread?: number;
  agreementDate?: string;
  interestConditionExpiryDate?: string | null;
  amortList?: AmortListResponse[] | null;
  timeToMaturity?: TimeMeasure;
  accountingAreaId?: string | null;
  accountingGroupId?: string | null;
  instrumentId?: string | null;
  customerSwap?: string | null;
  marginRate?: number;
  interestRatePlusSpread?: number;
  firstPayment?: number;
  loanDuration?: TimeMeasure;
};
export type CalculationInfoResponse = LoanInfoResponse & {
  eventDescription?: EventDescriptionCode;
  repayAmount?: number;
  eventDueDate?: string | null;
  eventValueDate?: string | null;
  changeMethod?: ChangeMethodCode;
  calculationDateTime?: string;
  notionalAmountOnValueDate?: number;
  accruedInterest?: number;
  compensationAmount?: number;
  compensationAmountCalculated?: number;
  compensationAmountReported?: number | null;
  preEventMaturityDate?: string;
  postEventMaturityDate?: string;
  paymentReduction?: number;
  isOnTerm?: boolean;
  timeToMaturityReduction?: TimeMeasure;
  markedDataDateTime?: string;
  firstInterestDate?: string | null;
  lastInterestDate?: string | null;
  firstInstalmentFreeRepaymentDate?: string | null;
  calculationPath?: string | null;
  initialCosts?: number;
  amortizationProfile?: AmortizationProfile;
  fixedInterestYears?: number | null;
  fixedInterestPeriodType?: FixedInterestPeriodType;
};
export type TermDate = {
  dueDate?: string;
  remainingDebt?: number | null;
  notifiedDate?: string | null;
};
export type InTermDate = TermDate & {
  valueDate?: string;
};
export type OutOfTermDate = TermDate;
export type PartialRedemptionTermDateResponse = {
  lastNotifiedPaymentDate?: string | null;
  inTermDates?: InTermDate[] | null;
  outOfTermDates?: OutOfTermDate[] | null;
};
export type FullRepaymentRequest = {
  appliedDate?: string;
};
export type ValidationResponse = {
  calculationPath?: string | null;
  validationMessage?: string | null;
};
export type PriceSheetResponse = {
  contractLoanType?: ContractLoanTypeEnum;
  amortizationProfile?: AmortizationProfile;
  isLeasing?: boolean;
  yearsToMaturity?: number;
  yearsWithFixedRate?: number;
  paymentFreq?: PaymentFreqEnum;
  spread?: number;
  isCheapest?: boolean;
};
export type InterestSheetResponse = {
  yearsToMaturity?: number;
  yearsWithFixedRate?: number;
  interestAnnuity?: number;
  interestSerial?: number;
};
export type NewLoanRequest = {
  principalValueDate?: string;
  interestPaymentFrequency?: PaymentFreqEnum;
  principalAmount?: number;
  loanProduct?: ContractLoanTypeEnum;
  amortizationProfile?: AmortizationProfile;
  maturityDateUnadjusted?: string;
  fixedInterestPeriodType?: FixedInterestPeriodType;
  fixedInterestYears?: number | null;
  firstPayDateUnadjusted?: string;
  instalmentFreeTerms?: number;
  marginRate?: number;
  marginMethod?: MarginCalculationMethod;
};
export type NewLeasingRequest = {
  principalValueDate?: string;
  interestPaymentFrequency?: PaymentFreqEnum;
  principalAmount?: number;
  loanProduct?: ContractLoanTypeEnum;
  amortizationProfile?: AmortizationProfile;
  fixedInterestPeriodType?: FixedInterestPeriodType;
  fixedInterestYears?: number | null;
  instalmentFreeTerms?: number;
  marginRate?: number;
  marginMethod?: MarginCalculationMethod;
  scrap?: number;
  numberOfPayments?: number;
  leasingFee?: number;
  fixedFirstRepayment?: number | null;
  prePaid?: boolean;
};
export type NewLeasingRequestRead = {
  principalValueDate?: string;
  interestPaymentFrequency?: PaymentFreqEnum;
  principalAmount?: number;
  loanProduct?: ContractLoanTypeEnum;
  amortizationProfile?: AmortizationProfile;
  maturityDateUnadjusted?: string;
  fixedInterestPeriodType?: FixedInterestPeriodType;
  fixedInterestYears?: number | null;
  firstPayDateUnadjusted?: string;
  instalmentFreeTerms?: number;
  marginRate?: number;
  marginMethod?: MarginCalculationMethod;
  scrap?: number;
  numberOfPayments?: number;
  leasingFee?: number;
  fixedFirstRepayment?: number | null;
  prePaid?: boolean;
};
export type PartialRedemptionRequest = {
  amount?: number;
  appliedDate?: string;
  changeMethod?: ChangeMethodCode;
};
export type RedemptionStatusResponse = {
  data2LASID?: number;
  status?: RedemptionStatusEnum;
  message?: string | null;
  kreditAdvis?: number[] | null;
};
export type EligibleLoanResponse = {
  contractId?: string | null;
  loanProduct?: ContractLoanTypeEnum;
  sustainableFrameWork?: SustainableFrameworkType;
  notionalAmount?: number;
};
export type LoanOverview = {
  contractId?: string | null;
  loanProduct?: ContractLoanTypeEnum;
  sustainableFramework?: SustainableFrameworkType;
  loanName?: string | null;
  principalAmount?: number;
  notionalAmountEOB?: number;
  referenceDate?: string;
  redemptionValueEOB?: number;
  agreementDate?: string;
  startDate?: string;
  maturityDate?: string;
  interestConditionExpiryDate?: string;
  interestRate?: number;
  currency?: CurrencyType;
  interestPaymentFrequency?: PaymentFreqEnum;
  spread?: number;
};
export type PortfolioInfo = {
  contractId?: string | null;
  loanProduct?: ContractLoanTypeEnum;
  loanCategory?: LoanCategory;
  sustainableFramework?: SustainableFrameworkType;
  greenLoan?: boolean;
  currency?: CurrencyCode;
  principalAmount?: number | null;
  notionalAmount?: number | null;
  redemptionValue?: number | null;
  startDate?: string | null;
  maturityDate?: string | null;
  interestRate?: number | null;
  spread?: number | null;
  marginRate?: number | null;
  nextValueDate?: string | null;
  interestConditionExpiryDate?: string | null;
  interestPaymentFrequency?: PaymentFreqEnum;
  nonAccruedInterest?: number | null;
  disposableAmount?: number | null;
  interestType?: InterestType;
  bossInfoId?: string | null;
  bossInfoProjectName?: string | null;
  swaps?: string | null;
  swapRelatedContractId?: string | null;
  projectName?: string | null;
  relation?: Relation;
  legalName?: string | null;
  companyId?: string | null;
  asOfDate?: string | null;
};
export type PortfolioInfoResponse = {
  asOfDate?: string | null;
  ownerPortfolio?: PortfolioInfo[] | null;
  guaranteePortfolio?: PortfolioInfo[] | null;
  partnershipLiabilityPortfolio?: PortfolioInfo[] | null;
  billReceiverPortfolio?: PortfolioInfo[] | null;
};
export type CalculationNewLoanRequest = {
  principalAmount?: number;
  loanProduct?: ContractLoanTypeEnum;
  amortizationProfile?: AmortizationProfile;
  startDate?: string;
  maturityDateUnadjusted?: string;
  fixedInterestPeriodType?: FixedInterestPeriodType;
  fixedInterestPeriodYears?: number | null;
  interestPaymentFrequency?: PaymentFreqEnum;
  firstPayDateUnadjusted?: string | null;
  instalmentFreeTerms?: number;
  calculationPath?: string | null;
  prePaid?: boolean | null;
  scrapValue?: number | null;
  fixedFirstRepayment?: number | null;
};
export type HolidayResponse = {
  startDate?: string;
  endDate?: string;
  holidayDates?: string[] | null;
};
export type AmortRecord = {
  paymentDate?: string;
  dueDate?: string;
  principal?: number;
  repayment?: number;
  interestPayment?: number;
  marginPayment?: number;
  extraOrdinaryRepayment?: number;
  payment?: number;
  remainingDebt?: number;
  notifiedDate?: string | null;
  knownInterest?: boolean;
};
export type LoanInfo = {
  contractId?: string | null;
  companyId?: string | null;
  companyLasId?: string | null;
  principalAmount?: number;
  startDate?: string;
  loanPurpose?: string | null;
  notionalAmount?: number;
  maturityDate?: string;
  loanProduct?: ContractLoanTypeEnum;
  sustainableFramework?: SustainableFrameworkType;
  greenLoan?: boolean;
  currency?: CurrencyCode;
  redemptionValue?: number;
  interestRate?: number;
  nextFixingDate?: string | null;
  interestPaymentFrequency?: PaymentFreqEnum;
  spread?: number;
  agreementDate?: string;
  interestConditionExpiryDate?: string;
  firstInterestDate?: string | null;
  lastInterestDate?: string | null;
  firstInterestDueDate?: string | null;
  lastInterestDueDate?: string | null;
  amortRecordList?: AmortRecord[] | null;
  timeToMaturity?: TimeMeasure;
  accountingAreaId?: string | null;
  accoutingGroupId?: string | null;
  instrumentId?: string | null;
  customerSwap?: string | null;
  firstPayment?: number;
  loanDuration?: TimeMeasure;
  marginRate?: number;
};
export type CalculationInfo = LoanInfo & {
  eventDescription?: EventDescriptionCode;
  repayAmount?: number;
  eventDueDate?: string | null;
  eventValueDate?: string | null;
  changeMethod?: ChangeMethodCode;
  calculationDateTime?: string;
  notionalAmountOnValueDate?: number;
  accruedInterest?: number;
  compensationAmount?: number;
  compensationAmountCalculated?: number;
  compensationAmountReported?: number | null;
  preEventMaturityDate?: string;
  postEventMaturityDate?: string;
  paymentReduction?: number;
  isOnTerm?: boolean;
  timeToMaturityReduction?: TimeMeasure;
  marketDataDateTime?: string;
  firstInstalmentFreeRepaymentDate?: string | null;
  calculationPath?: string | null;
  amortizationProfile?: AmortizationProfile;
  fixedInterestYears?: number | null;
  fixedInterestPeriodType?: FixedInterestPeriodType;
};
/**
 * Get loan information given information about companyLasId and/or contractId
 */
export function getSearchloans(
  {
    companyLasId,
    contractId,
    loanNo,
  }: {
    companyLasId?: string;
    contractId?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: LoanSearchResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/searchloans${QS.query(
        QS.explode({
          companyLasId,
          contractId,
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get information about loan relations given information about companyLasId and/or contractId
 */
export function getSearchloanrelations(
  {
    relationCompanyLasId,
    contractId,
  }: {
    relationCompanyLasId?: string;
    contractId?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: LoanSearchRelationResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/searchloanrelations${QS.query(
        QS.explode({
          relationCompanyLasId,
          contractId,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
export function getTestdeployment(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >('/testdeployment', {
      ...opts,
    }),
  );
}
/**
 * Get loan information from LAS for a given contractId
 */
export function getLoaninfoByContractId(
  contractId: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: (LoanInfoResponse | CalculationInfoResponse)[];
        }
      | {
          status: 204;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/loaninfo/${encodeURIComponent(contractId)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get a list of possible redemption dates for a loan on a given calculation path
 */
export function getPartialredemptiondateByCalculationPath(
  calculationPath: string,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: PartialRedemptionTermDateResponse;
        }
      | {
          status: 204;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/partialredemptiondate/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          RunDate: runDate,
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Fetch a stored calculation by calculation path from storage account
 */
export function getFetchcalculationfromstorageByCalculationPath(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CalculationInfoResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/fetchcalculationfromstorage/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Fetch a stored calculation by calculation path from storage account and Receive it as a file
 */
export function getFetchfilefromstorageByCalculationPath(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CalculationInfoResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/fetchfilefromstorage/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Update a calculation by calculation path and Receive a new calculation path on the updated calculation
 */
export function postCalculationByCalculationPathUpdate(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/${encodeURIComponent(calculationPath)}/update${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Add a manual compensation amount to an existing calculation given the calculation path. Receive a new calculation path on the updated calculation
 */
export function postCalculationAddmanualcompensationamountByCalculationPath(
  calculationPath: string,
  body?: number,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/addmanualcompensationamount/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      }),
    ),
  );
}
/**
 * Create a new calculation in the storage account given a contractId. Receive the calculation path on the new created calculation
 */
export function postCreatecalculationByContractId(
  contractId: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/createcalculation/${encodeURIComponent(contractId)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Create a rewnewed interest agreement calculation in the storage account given a contractId. Receive the calculation path on the new created calculation
 */
export function postCalculationRenewinterestagreementByContractId(
  contractId: string,
  {
    fixedInterestPeriodType,
    fixedInterestYears,
    loanNo,
  }: {
    fixedInterestPeriodType?: FixedInterestPeriodType;
    fixedInterestYears?: number;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/renewinterestagreement/${encodeURIComponent(contractId)}${QS.query(
        QS.explode({
          fixedInterestPeriodType,
          fixedInterestYears,
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
/**
 * Add a new event to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventByCalculationPathFullredemption(
  calculationPath: string,
  fullRepaymentRequest?: FullRepaymentRequest,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/${encodeURIComponent(calculationPath)}/fullredemption${QS.query(
        QS.explode({
          runDate,
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: fullRepaymentRequest,
      }),
    ),
  );
}
/**
 * Create a new CalculationPath for StandAlone
 */
export function putCreatestandalonecalculationpath(
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/createstandalonecalculationpath${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'PUT',
      },
    ),
  );
}
/**
 * Validate data in a calculation given by a calculation path
 */
export function postCalculationCalculatepricesheet(
  body?: string[],
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: ValidationResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      '/calculation/calculatepricesheet',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      }),
    ),
  );
}
/**
 * Get a freshly calculated pricesheet.
 */
export function getCalculationPricesheet(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: PriceSheetResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >('/calculation/pricesheet', {
      ...opts,
    }),
  );
}
/**
 * Get a freshly calculated interestsheet.
 */
export function getCalculationInterestsheet(opts?: Oazapfts.RequestOpts) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: InterestSheetResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >('/calculation/interestsheet', {
      ...opts,
    }),
  );
}
/**
 * Add a new event to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventNewloanByCalculationPath(
  calculationPath: string,
  newLoanRequest?: NewLoanRequest,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/newloan/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          runDate,
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: newLoanRequest,
      }),
    ),
  );
}
/**
 * Add a new event to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventNewleasingByCalculationPath(
  calculationPath: string,
  newLeasingRequest?: NewLeasingRequest,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/newleasing/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          runDate,
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: newLeasingRequest,
      }),
    ),
  );
}
/**
 * Add a new event to an existing calculation given a calculation path. Receive a new calculation path on the updated calculation
 */
export function putAddcalculationeventByCalculationPathExtrarepayment(
  calculationPath: string,
  partialRedemptionRequest?: PartialRedemptionRequest,
  {
    runDate,
    loanNo,
  }: {
    runDate?: string;
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: string;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/addcalculationevent/${encodeURIComponent(calculationPath)}/extrarepayment${QS.query(
        QS.explode({
          runDate,
          loanNo,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'PUT',
        body: partialRedemptionRequest,
      }),
    ),
  );
}
/**
 * Send a stored calculation given a calculation path to LAS for execution. Receive a Boolean indication telling whether LAS accepted the request
 */
export function getExecutecalculationByCalculationPath(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: boolean;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/executecalculation/${encodeURIComponent(calculationPath)}${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Get a status for executing a calculation from LAS
 */
export function getStatusexecutecalculationByCalculationPath(
  calculationPath: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: RedemptionStatusResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(`/statusexecutecalculation/${encodeURIComponent(calculationPath)}`, {
      ...opts,
    }),
  );
}
/**
 * Validate data in a calculation given by a calculation path
 */
export function postValidatecalculationsByRequestId(
  requestId: string,
  body?: string[],
  {
    runDate,
  }: {
    runDate?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: ValidationResponse[];
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/validatecalculations/${encodeURIComponent(requestId)}${QS.query(
        QS.explode({
          runDate,
        }),
      )}`,
      oazapfts.json({
        ...opts,
        method: 'POST',
        body,
      }),
    ),
  );
}
/**
 * Get eligable loans based on CompanyId and EventType
 */
export function getEligibleloansByCompanyId(
  companyId: string,
  {
    eventDescription,
  }: {
    eventDescription?: EventDescriptionCode;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: EligibleLoanResponse[];
        }
      | {
          status: 204;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/eligibleloans/${encodeURIComponent(companyId)}${QS.query(
        QS.explode({
          eventDescription,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Gets loan overview. Takes referencedate as an optional querystring.
 */
export function getCustomerByIdLoanoverview(
  id: string,
  {
    referencedate,
  }: {
    referencedate?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: LoanOverview[];
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/customer/${encodeURIComponent(id)}/loanoverview${QS.query(
        QS.explode({
          referencedate,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Retrieves loan portfolio for client
 */
export function getCustomerByCompanyIdEodloanportfolio(
  companyId: string,
  {
    date,
  }: {
    date?: string;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: PortfolioInfoResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/customer/${encodeURIComponent(companyId)}/eodloanportfolio${QS.query(
        QS.explode({
          date,
        }),
      )}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Creates a calculation for a new loan
 */
export function postCalculationNewloan(
  calculationNewLoanRequest?: CalculationNewLoanRequest,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CalculationInfoResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      '/calculation/newloan',
      oazapfts.json({
        ...opts,
        method: 'POST',
        body: calculationNewLoanRequest,
      }),
    ),
  );
}
/**
 * Gets a list of holidays based on a start and end time
 */
export function getCalculationByCalculationPathHolidaysAndStartDateEndDate(
  calculationPath: string,
  startDate: string,
  endDate: string,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: HolidayResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/${encodeURIComponent(calculationPath)}/holidays/${encodeURIComponent(startDate)}/${encodeURIComponent(endDate)}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Gets a list of holidays based on usage
 */
export function getCalculationByCalculationPathHolidaysAndHolidayUsage(
  calculationPath: string,
  holidayUsage: HolidayUsage,
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: HolidayResponse;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/${encodeURIComponent(calculationPath)}/holidays/${encodeURIComponent(holidayUsage)}`,
      {
        ...opts,
      },
    ),
  );
}
/**
 * Extract additional information on calculations
 */
export function postCalculationByCalculationPathGetcalculationdetails(
  calculationPath: string,
  {
    loanNo,
  }: {
    loanNo?: number;
  } = {},
  opts?: Oazapfts.RequestOpts,
) {
  return oazapfts.ok(
    oazapfts.fetchJson<
      | {
          status: 200;
          data: CalculationInfo;
        }
      | {
          status: 400;
          data: ValidationErrorResponse;
        }
      | {
          status: 500;
          data: ErrorResponse;
        }
    >(
      `/calculation/${encodeURIComponent(calculationPath)}/getcalculationdetails${QS.query(
        QS.explode({
          loanNo,
        }),
      )}`,
      {
        ...opts,
        method: 'POST',
      },
    ),
  );
}
export enum ContractLoanTypeEnum {
  NurUdlan = 'NUR_udl\u00E5n',
  UdlanModelB = 'Udl\u00E5n_Model_B',
  KKvar = 'KKvar',
  KKmax = 'KKmax',
  KKbasis = 'KKbasis',
  KKcibor = 'KKcibor',
  KKcita = 'KKcita',
  KKfast = 'KKfast',
  K4Udlan = 'K4_udl\u00E5n',
  AiKontant = 'AI_kontant',
  Rentetilpasningslan = 'Rentetilpasningsl\u00E5n',
  Indekslan = 'Indeksl\u00E5n',
  Obligationslan = 'Obligationsl\u00E5n',
  AiLan = 'AI_l\u00E5n',
  Swap = 'Swap',
  Renteswap = 'Renteswap',
  Valutaswap = 'Valutaswap',
  ValutaTermin = 'ValutaTermin',
  ValutaSpot = 'ValutaSpot',
  KontantlaanAiKontant = 'Kontantlaan_AI_kontant',
  KontantlaanK4Udlan = 'Kontantlaan_K4_udl\u00E5n',
  Byggekredit = 'Byggekredit',
  UnDefined = 'UnDefined',
}
export enum ContractStatus {
  NotYetActive = 'NotYetActive',
  Active = 'Active',
  Archived = 'Archived',
}
export enum SustainableFrameworkType {
  Gbf2017 = 'GBF2017',
  Gbf2022 = 'GBF2022',
  None = 'None',
}
export enum CurrencyCode {
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD',
  Chf = 'CHF',
  Undefined = 'Undefined',
}
export enum PaymentFreqEnum {
  UnDefined = 'UnDefined',
  M1 = 'M1',
  M2 = 'M2',
  M3 = 'M3',
  M4 = 'M4',
  M6 = 'M6',
  M12 = 'M12',
}
export enum InterestRateStatus {
  Fixed = 'Fixed',
  Estimated = 'Estimated',
  BeforeInterestConditionExpiryDate = 'BeforeInterestConditionExpiryDate',
  AfterInterestConditionExpiryDate = 'AfterInterestConditionExpiryDate',
}
export enum EventDescriptionCode {
  Conversion = 'Conversion',
  FullRedemption = 'FullRedemption',
  ExtraRepayment = 'ExtraRepayment',
  NewLoan = 'NewLoan',
  Redemption = 'Redemption',
}
export enum ChangeMethodCode {
  ReducedTimeToMaturity = 'ReducedTimeToMaturity',
  ReducedPayment = 'ReducedPayment',
  NewLoan = 'NewLoan',
}
export enum AmortizationProfile {
  Bullet = 'Bullet',
  Annuity = 'Annuity',
  Serial = 'Serial',
  Custom = 'Custom',
  UnDefined = 'UnDefined',
}
export enum FixedInterestPeriodType {
  Cheapest = 'Cheapest',
  ToMaturity = 'ToMaturity',
  Years = 'Years',
  UnDefined = 'UnDefined',
}
export enum MarginCalculationMethod {
  MarginCalculatedByRemainingDebt = 'MarginCalculatedByRemainingDebt',
  MarginCalculatedByPrincipal = 'MarginCalculatedByPrincipal',
}
export enum RedemptionStatusEnum {
  NotStarted = 'NotStarted',
  Ok = 'OK',
  Error = 'Error',
  Running = 'Running',
  NotRelevant = 'NotRelevant',
  Undefined = 'Undefined',
}
export enum CurrencyType {
  Undefined = 'Undefined',
  Aud = 'AUD',
  Cad = 'CAD',
  Chf = 'CHF',
  Dkk = 'DKK',
  Eur = 'EUR',
  Gbp = 'GBP',
  Hkd = 'HKD',
  Jpy = 'JPY',
  Nok = 'NOK',
  Nzd = 'NZD',
  Sek = 'SEK',
  Usd = 'USD',
  Cny = 'CNY',
  Pln = 'PLN',
  Sgd = 'SGD',
  Mxn = 'MXN',
  Zar = 'ZAR',
  Ron = 'RON',
  Huf = 'HUF',
  Try = 'TRY',
  Isk = 'ISK',
  Brl = 'BRL',
  Rub = 'RUB',
  Krw = 'KRW',
  Twd = 'TWD',
  Thb = 'THB',
  Myr = 'MYR',
  Idr = 'IDR',
  Inr = 'INR',
}
export enum LoanCategory {
  ContractLoan = 'ContractLoan',
  ConstructionCredit = 'ConstructionCredit',
  BondLoan = 'BondLoan',
  SubsidizedLoan = 'SubsidizedLoan',
  Swap = 'Swap',
  Undefined = 'Undefined',
}
export enum InterestType {
  Fixed = 'Fixed',
  Floating = 'Floating',
  Undefined = 'Undefined',
}
export enum Relation {
  Owner = 'Owner',
  Guarantee = 'Guarantee',
  PartnershipLiability = 'PartnershipLiability',
  BillReceiver = 'BillReceiver',
  Undefined = 'Undefined',
}
export enum HolidayUsage {
  StandAlone = 'StandAlone',
  NewLoan = 'NewLoan',
  LoanOverview = 'LoanOverview',
}

// #region INJECTED PATHS
/* The generated output is not a direct result of oazapfts' default behavior.
 * It instead incorporates our custom code, which adds path placeholder properties for MSW mocking, into the output of oazapfts. */
// {{{

const _getSearchloansPath = () => defaults.baseUrl + '/searchloans';
Object.defineProperty(getSearchloans, 'path', {
  get: _getSearchloansPath,
  set: _getSearchloansPath,
});
/** @path `/searchloans` */
getSearchloans.path = getSearchloans.path satisfies string;

const _getSearchloanrelationsPath = () =>
  defaults.baseUrl + '/searchloanrelations';
Object.defineProperty(getSearchloanrelations, 'path', {
  get: _getSearchloanrelationsPath,
  set: _getSearchloanrelationsPath,
});
/** @path `/searchloanrelations` */
getSearchloanrelations.path = getSearchloanrelations.path satisfies string;

const _getTestdeploymentPath = () => defaults.baseUrl + '/testdeployment';
Object.defineProperty(getTestdeployment, 'path', {
  get: _getTestdeploymentPath,
  set: _getTestdeploymentPath,
});
/** @path `/testdeployment` */
getTestdeployment.path = getTestdeployment.path satisfies string;

const _getLoaninfoByContractIdPath = () =>
  defaults.baseUrl + '/loaninfo/:contractId';
Object.defineProperty(getLoaninfoByContractId, 'path', {
  get: _getLoaninfoByContractIdPath,
  set: _getLoaninfoByContractIdPath,
});
/** @path `/loaninfo/:contractId` */
getLoaninfoByContractId.path = getLoaninfoByContractId.path satisfies string;

const _getPartialredemptiondateByCalculationPathPath = () =>
  defaults.baseUrl + '/partialredemptiondate/:calculationPath';
Object.defineProperty(getPartialredemptiondateByCalculationPath, 'path', {
  get: _getPartialredemptiondateByCalculationPathPath,
  set: _getPartialredemptiondateByCalculationPathPath,
});
/** @path `/partialredemptiondate/:calculationPath` */
getPartialredemptiondateByCalculationPath.path =
  getPartialredemptiondateByCalculationPath.path satisfies string;

const _getFetchcalculationfromstorageByCalculationPathPath = () =>
  defaults.baseUrl + '/fetchcalculationfromstorage/:calculationPath';
Object.defineProperty(getFetchcalculationfromstorageByCalculationPath, 'path', {
  get: _getFetchcalculationfromstorageByCalculationPathPath,
  set: _getFetchcalculationfromstorageByCalculationPathPath,
});
/** @path `/fetchcalculationfromstorage/:calculationPath` */
getFetchcalculationfromstorageByCalculationPath.path =
  getFetchcalculationfromstorageByCalculationPath.path satisfies string;

const _getFetchfilefromstorageByCalculationPathPath = () =>
  defaults.baseUrl + '/fetchfilefromstorage/:calculationPath';
Object.defineProperty(getFetchfilefromstorageByCalculationPath, 'path', {
  get: _getFetchfilefromstorageByCalculationPathPath,
  set: _getFetchfilefromstorageByCalculationPathPath,
});
/** @path `/fetchfilefromstorage/:calculationPath` */
getFetchfilefromstorageByCalculationPath.path =
  getFetchfilefromstorageByCalculationPath.path satisfies string;

const _postCalculationByCalculationPathUpdatePath = () =>
  defaults.baseUrl + '/calculation/:calculationPath/update';
Object.defineProperty(postCalculationByCalculationPathUpdate, 'path', {
  get: _postCalculationByCalculationPathUpdatePath,
  set: _postCalculationByCalculationPathUpdatePath,
});
/** @path `/calculation/:calculationPath/update` */
postCalculationByCalculationPathUpdate.path =
  postCalculationByCalculationPathUpdate.path satisfies string;

const _postCalculationAddmanualcompensationamountByCalculationPathPath = () =>
  defaults.baseUrl +
  '/calculation/addmanualcompensationamount/:calculationPath';
Object.defineProperty(
  postCalculationAddmanualcompensationamountByCalculationPath,
  'path',
  {
    get: _postCalculationAddmanualcompensationamountByCalculationPathPath,
    set: _postCalculationAddmanualcompensationamountByCalculationPathPath,
  },
);
/** @path `/calculation/addmanualcompensationamount/:calculationPath` */
postCalculationAddmanualcompensationamountByCalculationPath.path =
  postCalculationAddmanualcompensationamountByCalculationPath.path satisfies string;

const _postCreatecalculationByContractIdPath = () =>
  defaults.baseUrl + '/createcalculation/:contractId';
Object.defineProperty(postCreatecalculationByContractId, 'path', {
  get: _postCreatecalculationByContractIdPath,
  set: _postCreatecalculationByContractIdPath,
});
/** @path `/createcalculation/:contractId` */
postCreatecalculationByContractId.path =
  postCreatecalculationByContractId.path satisfies string;

const _postCalculationRenewinterestagreementByContractIdPath = () =>
  defaults.baseUrl + '/calculation/renewinterestagreement/:contractId';
Object.defineProperty(
  postCalculationRenewinterestagreementByContractId,
  'path',
  {
    get: _postCalculationRenewinterestagreementByContractIdPath,
    set: _postCalculationRenewinterestagreementByContractIdPath,
  },
);
/** @path `/calculation/renewinterestagreement/:contractId` */
postCalculationRenewinterestagreementByContractId.path =
  postCalculationRenewinterestagreementByContractId.path satisfies string;

const _putAddcalculationeventByCalculationPathFullredemptionPath = () =>
  defaults.baseUrl + '/addcalculationevent/:calculationPath/fullredemption';
Object.defineProperty(
  putAddcalculationeventByCalculationPathFullredemption,
  'path',
  {
    get: _putAddcalculationeventByCalculationPathFullredemptionPath,
    set: _putAddcalculationeventByCalculationPathFullredemptionPath,
  },
);
/** @path `/addcalculationevent/:calculationPath/fullredemption` */
putAddcalculationeventByCalculationPathFullredemption.path =
  putAddcalculationeventByCalculationPathFullredemption.path satisfies string;

const _putCreatestandalonecalculationpathPath = () =>
  defaults.baseUrl + '/createstandalonecalculationpath';
Object.defineProperty(putCreatestandalonecalculationpath, 'path', {
  get: _putCreatestandalonecalculationpathPath,
  set: _putCreatestandalonecalculationpathPath,
});
/** @path `/createstandalonecalculationpath` */
putCreatestandalonecalculationpath.path =
  putCreatestandalonecalculationpath.path satisfies string;

const _postCalculationCalculatepricesheetPath = () =>
  defaults.baseUrl + '/calculation/calculatepricesheet';
Object.defineProperty(postCalculationCalculatepricesheet, 'path', {
  get: _postCalculationCalculatepricesheetPath,
  set: _postCalculationCalculatepricesheetPath,
});
/** @path `/calculation/calculatepricesheet` */
postCalculationCalculatepricesheet.path =
  postCalculationCalculatepricesheet.path satisfies string;

const _getCalculationPricesheetPath = () =>
  defaults.baseUrl + '/calculation/pricesheet';
Object.defineProperty(getCalculationPricesheet, 'path', {
  get: _getCalculationPricesheetPath,
  set: _getCalculationPricesheetPath,
});
/** @path `/calculation/pricesheet` */
getCalculationPricesheet.path = getCalculationPricesheet.path satisfies string;

const _getCalculationInterestsheetPath = () =>
  defaults.baseUrl + '/calculation/interestsheet';
Object.defineProperty(getCalculationInterestsheet, 'path', {
  get: _getCalculationInterestsheetPath,
  set: _getCalculationInterestsheetPath,
});
/** @path `/calculation/interestsheet` */
getCalculationInterestsheet.path =
  getCalculationInterestsheet.path satisfies string;

const _putAddcalculationeventNewloanByCalculationPathPath = () =>
  defaults.baseUrl + '/addcalculationevent/newloan/:calculationPath';
Object.defineProperty(putAddcalculationeventNewloanByCalculationPath, 'path', {
  get: _putAddcalculationeventNewloanByCalculationPathPath,
  set: _putAddcalculationeventNewloanByCalculationPathPath,
});
/** @path `/addcalculationevent/newloan/:calculationPath` */
putAddcalculationeventNewloanByCalculationPath.path =
  putAddcalculationeventNewloanByCalculationPath.path satisfies string;

const _putAddcalculationeventNewleasingByCalculationPathPath = () =>
  defaults.baseUrl + '/addcalculationevent/newleasing/:calculationPath';
Object.defineProperty(
  putAddcalculationeventNewleasingByCalculationPath,
  'path',
  {
    get: _putAddcalculationeventNewleasingByCalculationPathPath,
    set: _putAddcalculationeventNewleasingByCalculationPathPath,
  },
);
/** @path `/addcalculationevent/newleasing/:calculationPath` */
putAddcalculationeventNewleasingByCalculationPath.path =
  putAddcalculationeventNewleasingByCalculationPath.path satisfies string;

const _putAddcalculationeventByCalculationPathExtrarepaymentPath = () =>
  defaults.baseUrl + '/addcalculationevent/:calculationPath/extrarepayment';
Object.defineProperty(
  putAddcalculationeventByCalculationPathExtrarepayment,
  'path',
  {
    get: _putAddcalculationeventByCalculationPathExtrarepaymentPath,
    set: _putAddcalculationeventByCalculationPathExtrarepaymentPath,
  },
);
/** @path `/addcalculationevent/:calculationPath/extrarepayment` */
putAddcalculationeventByCalculationPathExtrarepayment.path =
  putAddcalculationeventByCalculationPathExtrarepayment.path satisfies string;

const _getExecutecalculationByCalculationPathPath = () =>
  defaults.baseUrl + '/executecalculation/:calculationPath';
Object.defineProperty(getExecutecalculationByCalculationPath, 'path', {
  get: _getExecutecalculationByCalculationPathPath,
  set: _getExecutecalculationByCalculationPathPath,
});
/** @path `/executecalculation/:calculationPath` */
getExecutecalculationByCalculationPath.path =
  getExecutecalculationByCalculationPath.path satisfies string;

const _getStatusexecutecalculationByCalculationPathPath = () =>
  defaults.baseUrl + '/statusexecutecalculation/:calculationPath';
Object.defineProperty(getStatusexecutecalculationByCalculationPath, 'path', {
  get: _getStatusexecutecalculationByCalculationPathPath,
  set: _getStatusexecutecalculationByCalculationPathPath,
});
/** @path `/statusexecutecalculation/:calculationPath` */
getStatusexecutecalculationByCalculationPath.path =
  getStatusexecutecalculationByCalculationPath.path satisfies string;

const _postValidatecalculationsByRequestIdPath = () =>
  defaults.baseUrl + '/validatecalculations/:requestId';
Object.defineProperty(postValidatecalculationsByRequestId, 'path', {
  get: _postValidatecalculationsByRequestIdPath,
  set: _postValidatecalculationsByRequestIdPath,
});
/** @path `/validatecalculations/:requestId` */
postValidatecalculationsByRequestId.path =
  postValidatecalculationsByRequestId.path satisfies string;

const _getEligibleloansByCompanyIdPath = () =>
  defaults.baseUrl + '/eligibleloans/:companyId';
Object.defineProperty(getEligibleloansByCompanyId, 'path', {
  get: _getEligibleloansByCompanyIdPath,
  set: _getEligibleloansByCompanyIdPath,
});
/** @path `/eligibleloans/:companyId` */
getEligibleloansByCompanyId.path =
  getEligibleloansByCompanyId.path satisfies string;

const _getCustomerByIdLoanoverviewPath = () =>
  defaults.baseUrl + '/customer/:id/loanoverview';
Object.defineProperty(getCustomerByIdLoanoverview, 'path', {
  get: _getCustomerByIdLoanoverviewPath,
  set: _getCustomerByIdLoanoverviewPath,
});
/** @path `/customer/:id/loanoverview` */
getCustomerByIdLoanoverview.path =
  getCustomerByIdLoanoverview.path satisfies string;

const _getCustomerByCompanyIdEodloanportfolioPath = () =>
  defaults.baseUrl + '/customer/:companyId/eodloanportfolio';
Object.defineProperty(getCustomerByCompanyIdEodloanportfolio, 'path', {
  get: _getCustomerByCompanyIdEodloanportfolioPath,
  set: _getCustomerByCompanyIdEodloanportfolioPath,
});
/** @path `/customer/:companyId/eodloanportfolio` */
getCustomerByCompanyIdEodloanportfolio.path =
  getCustomerByCompanyIdEodloanportfolio.path satisfies string;

const _postCalculationNewloanPath = () =>
  defaults.baseUrl + '/calculation/newloan';
Object.defineProperty(postCalculationNewloan, 'path', {
  get: _postCalculationNewloanPath,
  set: _postCalculationNewloanPath,
});
/** @path `/calculation/newloan` */
postCalculationNewloan.path = postCalculationNewloan.path satisfies string;

const _getCalculationByCalculationPathHolidaysAndStartDateEndDatePath = () =>
  defaults.baseUrl +
  '/calculation/:calculationPath/holidays/:startDate/:endDate';
Object.defineProperty(
  getCalculationByCalculationPathHolidaysAndStartDateEndDate,
  'path',
  {
    get: _getCalculationByCalculationPathHolidaysAndStartDateEndDatePath,
    set: _getCalculationByCalculationPathHolidaysAndStartDateEndDatePath,
  },
);
/** @path `/calculation/:calculationPath/holidays/:startDate/:endDate` */
getCalculationByCalculationPathHolidaysAndStartDateEndDate.path =
  getCalculationByCalculationPathHolidaysAndStartDateEndDate.path satisfies string;

const _getCalculationByCalculationPathHolidaysAndHolidayUsagePath = () =>
  defaults.baseUrl + '/calculation/:calculationPath/holidays/:holidayUsage';
Object.defineProperty(
  getCalculationByCalculationPathHolidaysAndHolidayUsage,
  'path',
  {
    get: _getCalculationByCalculationPathHolidaysAndHolidayUsagePath,
    set: _getCalculationByCalculationPathHolidaysAndHolidayUsagePath,
  },
);
/** @path `/calculation/:calculationPath/holidays/:holidayUsage` */
getCalculationByCalculationPathHolidaysAndHolidayUsage.path =
  getCalculationByCalculationPathHolidaysAndHolidayUsage.path satisfies string;

const _postCalculationByCalculationPathGetcalculationdetailsPath = () =>
  defaults.baseUrl + '/calculation/:calculationPath/getcalculationdetails';
Object.defineProperty(
  postCalculationByCalculationPathGetcalculationdetails,
  'path',
  {
    get: _postCalculationByCalculationPathGetcalculationdetailsPath,
    set: _postCalculationByCalculationPathGetcalculationdetailsPath,
  },
);
/** @path `/calculation/:calculationPath/getcalculationdetails` */
postCalculationByCalculationPathGetcalculationdetails.path =
  postCalculationByCalculationPathGetcalculationdetails.path satisfies string;

// }}}
// #endregion
